import React from 'react';
import tw, {styled} from 'twin.macro';
import {useTranslation} from 'react-i18next';
import Layout from '../components/Layout';
import {Container} from '../components/Grid';
import SEO from '../components/Seo';
import {graphql} from 'gatsby';

const StyledContainer = styled(Container)`
  max-width: 1180px;
  margin-top: ${props => props.theme.mobileHeaderHeight};
  padding-top: 60px;

  @media ${props => props.theme.screens.md} {
    margin-top: ${props => props.theme.headerHeight};
  }

  h1 {
    ${tw`text-primary mb-8 text-center`}
    font-size: 32px;
  }

  h3 {
    ${tw`text-primary mb-4`}
    font-size: 32px;
  }

  p {
    ${tw`font-medium mb-4`}
  }
`;

const cookieConsent = () => {
  const {t} = useTranslation();
  return (
    <Layout>
      <SEO
        title={t('consent_to_processing_cookies_seo_title')}
        description={t('consent_to_processing_cookies_seo_description')}
        keywords={t('consent_to_processing_cookies_seo_keywords', {
          returnObjects: true,
        })}
      />
      <StyledContainer>
        <h1>{t('Souhlas se zpracováním souborů cookies')}</h1>
        <h3>{t('Co je cookie?')}</h3>
        <p>
          {t(
            'Cookie je krátký textový soubor, který navštívená webová stránka odešle do prohlížeče. Umožňuje webu zaznamenat informace o Vaší návštěvě, například preferovaný jazyk a další nastavení. Příští návštěva stránek tak může být snazší a produktivnější. Soubory cookie jsou důležité. Bez nich by procházení webu bylo mnohem složitější. Soubory cookies umožňují lepší využití našich serverů a přizpůsobení jejich obsahu Vašim potřebám. Soubory cookies používá téměř každá internetová stránka na světě. Soubory cookies jsou obecně užitečné, protože zvyšují uživatelskou přívětivost opakovaně navštívené internetové stránky.',
          )}
        </p>
        <h3>{t('Druhy souborů cookies')}</h3>
        <p>
          {t(
            'Existují dva druhy souborů cookies. Jednak relační (tedy dočasné) soubory cookie, které nám umožňují propojovat Vaše jednotlivé aktivity po dobu prohlížení webových stránek (tedy např. když si v prvním kroku vyplníte smlouvu a ve druhém zadáte e-mailovou adresu, děkuji těmto souborům Vám dokážeme poslat tu správnou, tedy Vaši smlouvu). V okamžiku otevření okna prohlížeče se tyto soubory aktivují a deaktivují se po zavření okna prohlížeče. Relační cookies jsou dočasné a po zavření prohlížeče se všechny tyto soubory vymažou.',
          )}
        </p>
        <p>
          {t(
            'Trvalé soubory cookie nám pomáhají Váš počítač identifikovat, jestliže opětovně navštívíte naši internetovou stránku. Další výhodou trvalých cookies je to, že nám umožňují přizpůsobit naše stránky Vašim potřebám.',
          )}
        </p>
        <h3>{t('Využívání souborů cookies')}</h3>
        <p>
          {t(
            'V souladu s ustanovením § 89 zákona č. 127/2005 Sb., o elektronických komunikacích, Vás tímto informujeme, že naše servery využívají pro svoji činnost soubory cookies.',
          )}
        </p>
        <p>
          {t(
            'Soubory cookies využíváme pouze v souladu s našimi obchodními podmínkami.',
          )}
        </p>
        <p>
          {t(
            'K personalizaci obsahu a reklam, poskytování funkcí sociálních médií a analýze naší návštěvnosti využíváme soubory cookie. Informace o tom, jak náš web používáte, sdílíme se svými partnery působícími v oblasti sociálních médií, inzerce a analýz.',
          )}
        </p>
      </StyledContainer>
    </Layout>
  );
};

export default cookieConsent;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {language: {eq: $language}, ns: {in: ["common", "seo_other"]}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
